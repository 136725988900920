/* @import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,400;0,600;0,700;1,200&display=swap"); */

 @import url('https://fonts.googleapis.com/css2?family=Raleway&family=Roboto&display=swap');

:root {
  --mainbackground: #81c408;
  --secbackground: #ffc107;
  --text-color-prim: #81c408;
  --text-color-sec: #ffc107;
}
body {
  
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a{
  text-decoration: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.rkm{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
.redd{
background-image: url(./img/red.jpg ) ;
background-size: cover;
}
.whitee{
background-image: url(./img/white.jpg) ;
background-size: cover;
}
.spring{
background-image: url(./img/spring.jpg) ;
background-size: cover;
}

.bg-primary {
  background-color: #81c408 !important;
}
.bg-dark {
  background-color: #45595b !important;
}
.text-primary {
 color: #81c408 !important;
}
.text-secondary {
 color: #ffc107 !important;
}
.bg-secondary {
  background-color: #ffc107 !important;
}
/*** Navbar Start ***/
.navbar .navbar-nav .nav-link {
  padding: 10px 15px;
  font-size: 16px;
  transition: .5s;
}

.navbar {
  height: 100px;
  border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active,
.fixed-top.bg-white .navbar .navbar-nav .nav-link:hover,
.fixed-top.bg-white .navbar .navbar-nav .nav-link.active {
  color: var(--mainbackground);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  vertical-align: middle;
  margin-left: 8px;
}

@media (min-width: 1200px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      visibility: hidden;
      top: 100%;
      transform: rotateX(-75deg);
      transform-origin: 0% 0%;
      border: 0;
      transition: .5s;
      opacity: 0;
  }
}

.dropdown .dropdown-menu a:hover {
  background: var(--secbackground);
  color: var(--mainbackground);
}

.navbar .nav-item:hover .dropdown-menu {
  transform: rotateX(0deg);
  visibility: visible;
  background: var(--bs-light) !important;
  border-radius: 10px !important;
  transition: .5s;
  opacity: 1;
}

#searchModal .modal-content {
  background: rgba(255, 255, 255, .8);
}
/*** Navbar End ***/



/*** Button Start ***/
.btn {
  font-weight: 600;
  transition: .5s;
}

.btn-square {
  width: 32px;
  height: 32px;
}

.btn-sm-square {
  width: 34px;
  height: 34px;
}

.btn-md-square {
  width: 44px;
  height: 44px;
}

.btn-lg-square {
  width: 56px;
  height: 56px;
}

.btn-square,
.btn-sm-square,
.btn-md-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

.btn.border-secondary {
  transition: 0.5s;
}

.btn.border-secondary:hover {
  background: var(--secbackground) !important;
  color: var(--bs-white) !important;
}
.btn-outline-primary:hover {
  background: var(--mainbackground) !important;
  color: var(--bs-white) !important;
}
.btn-outline-primary {
  border: solid #81c408 !important;
  color: var(--mainbackground) !important;
}

/*** Topbar Start ***/
.fixed-top {
  transition: 0.5s;
  background: var(--bs-white);
  border: 0;
}

.topbar {
  padding: 20px;
  border-radius: 230px 100px;
}

.topbar .top-info {
  font-size: 15px;
  line-height: 0;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
}

.topbar .top-link {
  font-size: 15px;
  line-height: 0;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
}

.topbar .top-link a {
  letter-spacing: 1px;
}

.topbar .top-link a small:hover {
  color: var(--secbackground) !important;
  transition: 0.5s;
}

.topbar .top-link a small:hover i {
  color: var(--mainbackground) !important;
}
/*** Topbar End ***/

/*** Hero Header ***/
.hero-header {
  background: linear-gradient(rgba(248, 223, 173, 0.1), rgba(248, 223, 173, 0.1)), url(./img/hero-img.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.margin{
  margin-top: 100px;
}
.carousel-item {
  position: relative;
}

.carousel-item a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
  background: linear-gradient(rgba(255, 181, 36, 0.7), rgba(255, 181, 36, 0.7));
}

.carousel-control-next,
.carousel-control-prev {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  border: 1px solid var(--bs-white);
  background: var(--mainbackground);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-control-next {
  margin-right: 20px;
}
li {
  list-style: none;
}

.carousel-control-prev {
  margin-left: 20px;
}

.page-header {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(./img/cart-page-header-img.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page-lemon {
  margin-top: 100px;
  position: relative;
  height: 300px;
  background-image: url(./img//Image3.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page-carot {
  margin-top: 100px;
  position: relative;
  height: 300px;
  background-image: url(./img/Image7.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page-chili {
  margin-top: 100px;
  position: relative;
  height: 300px;
  background-image: url(./img/Image5.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page-Grapes {
  margin-top: 100px;
  position: relative;
  height: 300px;
  background-image: url(./img/Image1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page-Onion {
  margin-top: 100px;
  position: relative;
  height: 300px;
  background-image: url(./img/Image9.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page-Orange {
  margin-top: 100px;
  position: relative;
  height: 300px;
  background-image: url(./img/Image2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page-Strawberries {
  margin-top: 100px;
  position: relative;
  height: 300px;
  background-image: url(./img/Image8.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 992px) {
  .hero-header,
  .page-header {
      margin-top: 152px !important;
  }
}

@media (max-width: 992px) {
  .hero-header,
  .page-header {
      margin-top: 97px !important;
  }
}
/*** Hero Header end ***/


/*** featurs Start ***/
.featurs .featurs-item .featurs-icon {
  position: relative;
  width: 120px;
  height: 120px;
}

.featurs .featurs-item .featurs-icon::after {
  content: "";
  width: 35px;
  height: 35px;
  background: var(--secbackground);
  position: absolute;
  bottom: -10px;
  transform: translate(-50%);
  transform: rotate(45deg);
  background: var(--secbackground);
  
}
/*** featurs End ***/


/*** service Start ***/
.service .service-item .service-content {
  position: relative;
  width: 250px; 
  height: 130px; 
  top: -50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
}

/*** service End ***/


/*** Fruits Start ***/
.fruite .tab-class .nav-item a.active {
  background: var(--secbackground) !important;
}

.fruite .tab-class .nav-item a.active span {
  color: var(--bs-white) !important; 
}

.fruite .fruite-categorie .fruite-name {
  line-height: 40px;
}

.fruite .fruite-categorie .fruite-name a {
  transition: 0.5s;
}

.fruite .fruite-categorie .fruite-name a:hover {
  color: var(--secbackground);
}

.fruite .fruite-item {
  height: 100%;
  transition: 0.5s;
}
.fruite .fruite-item:hover {
  box-shadow: 0 0 55px rgba(0, 0, 0, 0.4);
}

.fruite .fruite-item .fruite-img {
  overflow: hidden;
  transition: 0.5s;
  border-radius: 10px 10px 0 0;
}

.fruite .fruite-item .fruite-img img {
  transition: 0.5s;
}

.fruite .fruite-item .fruite-img img:hover {
  transform: scale(1.3);
}
/*** Fruits End ***/


/*** vesitable Start ***/
.vesitable .vesitable-item {
  height: 100%;
  transition: 0.5s;
}

.vesitable .vesitable-item:hover {
  box-shadow: 0 0 55px rgba(0, 0, 0, 0.4);
}

.vesitable .vesitable-item .vesitable-img {
  overflow: hidden;
  transition: 0.5s;
  border-radius: 10px 10px 0 0;
}

.vesitable .vesitable-item .vesitable-img img {
  transition: 0.5s;
}

.vesitable .vesitable-item .vesitable-img img:hover {
  transform: scale(1.2);
}

.vesitable .owl-stage {
  margin: 50px 0;
  position: relative;
}

.vesitable .owl-nav .owl-prev {
  position: absolute;
  top: -8px;
  right: 0;
  color: var(--mainbackground);
  padding: 5px 25px;
  border: 1px solid var(--secbackground);
  border-radius: 20px;
  transition: 0.5s;

}

.vesitable .owl-nav .owl-prev:hover {
  background: var(--secbackground);
  color: var(--bs-white);
}

.vesitable .owl-nav .owl-next {
  position: absolute;
  top: -8px;
  right: 88px;
  color: var(--mainbackground);
  padding: 5px 25px;
  border: 1px solid var(--secbackground);
  border-radius: 20px;
  transition: 0.5s;
}

.vesitable .owl-nav .owl-next:hover {
  background: var(--secbackground);
  color: var(--bs-white);
}
/*** vesitable End ***/


/*** Banner Section Start ***/
.banner .banner-btn:hover {
  background: var(--mainbackground);
}
/*** Banner Section End ***/

/*** Facts Start ***/
.counter {
  height: 100%;
  text-align: center;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
}

.counter i {
  font-size: 60px;
  margin-bottom: 25px;
}

.counter h4 {
  color: var(--mainbackground);
  letter-spacing: 1px;
  text-transform: uppercase;
}

.counter h1 {
  margin-bottom: 0;
}
/*** Facts End ***/













about-img img {
  position: relative;
  z-index: 2;
}



/*** Footer Start ***/
.footer .footer-item .btn-link {
  line-height: 35px;
  color: rgba(255, 255, 255, .5);
  transition: 0.5s;
  text-decoration: none;
}

.footer .footer-item .btn-link:hover {
  color: var(--secbackground) !important;
}

.footer .footer-item p.mb-4 {
  line-height: 35px;
}
/*** Footer End ***/

